import React, { Component } from 'react';
import PropTypes from 'prop-types';
let activeCategory = 0;

class Steps extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeCategoryId: ''
    };

    activeCategory = 0;
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return this.state.activeCategoryId !== nextProps.activeCategoryId;
  }

  componentDidUpdate() {
    if(this.props.activeCategoryId !== this.state.activeCategoryId) {
      [...this.props.categoriesTitle].forEach((category, index) => {
        if(category['id'] === this.props.activeCategoryId) {
            activeCategory = index;
        }
      });
      this.setState({
        activeCategoryId: this.props.activeCategoryId
      });
    }
  }

  componentDidMount() {
    this.setState({
      activeCategoryId: this.props.activeCategoryId
    });
  }

  render () {
    if (this.props.categoriesTitle.length !== 0) {
      return (
        <div className='steps'>
          <Numbers
            categories={this.props.categoriesTitle}
            activeCategory={activeCategory}
          />
          <div className='title-block aligned center'>
            <Titles
              categories={this.props.categoriesTitle}
              activeCategory={activeCategory}
            />
          </div>
        </div>
      )
    } else {
      return ('');
    }
  }
}
Steps.propTypes = {
  categoriesTitle: PropTypes.array,
  activeCategoryId: PropTypes.string,
};

const Numbers = ({categories, activeCategory}) => {
  return (
    <div className='steper'>
      {
        [...categories].map((category, index) => {
          let className = 'number';
          if(index === activeCategory) { className = className + ' active';}
          if(index < activeCategory) { className = className + ' old';}
          return(
            <span className={className} key={category.id}>{index + 1}</span>
          )
        }
        )
      }
    </div>
  )
};
const Titles = ({categories, activeCategory}) => {
  return (
    [...categories].map((category,index,arr) => {
      let className = 'title';
      if(index === activeCategory) { className = className + ' active';}
      if(index < activeCategory) { className = className + ' old';}
      return (
        <div className={className} key={category.id}><span className='number active'>{index + 1}</span> { category['name'] }</div>
      )
    }
    )
  )
};

export default Steps;
