import React, { Component } from 'react';
import {apiCommunication} from '../Utils/Api';
import FormFields from './FormFields';
import {Container} from "semantic-ui-react";

const QUERY_BUSINESSSEGMENTS = '/business_segments';

class BusinessSegment extends Component {
  constructor(props) {

    super(props);
    
    this.state = {
      businessSegment: null,
    };
  }

  async componentDidMount() {
    const businessSegment = await apiCommunication('GET', `${QUERY_BUSINESSSEGMENTS}?code=${this.props.location.state.segment.toLowerCase()}`);
    this.setState({
      businessSegment: businessSegment['hydra:member'][0],
    });
  }

  render () {
    const { businessSegment } = this.state;
    return (
      <>
      {businessSegment ? (
        <Container className="custom-container form-content">
          <h1 className="aligned center">{businessSegment.name}</h1>
          <FormFields
            segment={businessSegment.code}
          />
        </Container>
        ):''}
      </>
    );
  }
}

export default BusinessSegment;
