import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import 'semantic-ui-css/semantic.min.css'
import 'font-awesome/css/font-awesome.css';
import * as serviceWorker from './serviceWorker';
// Import your reducers and routes here
import Layout from './layout';
import Homepage from './components/Homepage';
import BusinessSegment from './components/Form/BusinessSegment';
import {
  LegalInformation,
  Privacy
} from './components/StaticPages';
import {
  Validate,
} from './components/Form';

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={Layout({Content: Homepage})} strict={true} exact={true}/>
        <Route path="/validation" component={Layout({Content: Validate})} strict={true} exact={true}/>
        <Route path="/mentions-legales" component={Layout({Content: LegalInformation})} strict={true} exact={true}/>
        <Route path="/politique-vie-privee" component={Layout({Content: Privacy})} strict={true} exact={true}/>
        <Route path="/s/:segment" component={Layout({Content: BusinessSegment})} strict={true} exact={true}/>
        <Route render={() => <h1>Not Found</h1>} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
