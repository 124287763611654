import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import {LinkBtn, Buttons} from './Buttons';

export default function QuestionCard ({isLast, nextStep, prevStep, answer, answers, question, category, survey, segment}) {
    return (
        <Card>
            <Card.Content className='question'>
                <Card.Header>{question['title']}</Card.Header>
                <div className='answers'>
                    <Buttons
                        answers={answers}
                        value={answer}
                        onClick={nextStep}
                        questionId={question['@id']}
                        categoryId={category}
                    />
                </div>
                <div className='actions'>
                    <LinkBtn
                        css='ui icon left labeled button previous'
                        onClick={prevStep}
                    ><i aria-hidden="true" className="left chevron icon"/>Précédent</LinkBtn>
                    {isLast ?
                        <Link
                            className='ui button next final'
                            to={{
                                pathname: '/validation',
                                state: {surveyID: survey, segment: segment}
                            }}
                        >&Eacute;tape suivante</Link>
                        :
                        <LinkBtn
                            css='ui icon right labeled button next'
                            onClick={nextStep}
                        >
                            Suivant <i aria-hidden="true" className="right chevron icon"/>
                        </LinkBtn>
                    }
                </div>
            </Card.Content>
        </Card>
    );
}
