import React from 'react';
import { Link } from 'react-router-dom';

const Year = new Date().getFullYear();

const Footer = () => (
  <footer>
    <div className='ui container custom-container'>
      <div className='ui padded center aligned grid'>
        <div className='row contact-link'>
          <a href='https://acceptablesavenirs.eu/contact/' rel="noopener noreferrer" target='_blank'>Contactez-nous</a>
        </div>
        <div className='row footer-links'>
          <Link to="/mentions-legales">Mentions légales</Link>&nbsp;-&nbsp;
          <Link to="/politique-vie-privee">Politique de vie privée</Link>
        </div>
        <div className='row footer-copyright'>
          &copy;{Year} Acceptables Avenirs - Tous droits réservés
        </div>
      </div>
      <div></div>
    </div>
  </footer>
);

export default Footer;
