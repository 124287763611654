import React from 'react';
import { Container } from 'semantic-ui-react';

const Privacy = () => (
  <Container className='custom-container static-page'>
    <div className='title aligned center'>
      <h1>Politique de Confidentialité d’Acceptables Avenirs</h1>
    </div>
    <p className='introduction aligned center'>Acceptables Avenirs attache toute l’importance nécessaire à la
      protection et la confidentialité de la vie professionnelle et privée des utilisateurs de ses applications et
      logiciels accessibles via internet et plus particulièrement de leurs données à caractère personnel. Notre
      Politique de confidentialité exposée ci-dessous permet de comprendre quelles données à caractère personnel nous
      collectons et ce que nous en faisons, ainsi que la façon dont vous pouvez protéger votre vie professionnelle et
      privée dans le cadre de nos applications et logiciels accessibles via internet.
    </p>
    <h2>Quel type de données vous concernant sont collectées par Acceptables Avenirs ?</h2>
    <p>
      Dans le cadre de l’utilisation des applications et logiciels d’Acceptables Avenirs accessibles via internet, les
      catégories de données collectées sont :

      Données d’identification : nom, prénom, courriel professionnel, nom de l’entreprise qui vous emploie, numéro de
      téléphone professionnel. Données de suivi de la relation utilisateur : demandes d’assistance, correspondance avec
      l’utilisateur.

      Données d’usage : produits utilisés, éléments de description de vos projets, géolocalisation, etc.
    </p>
    <h2>De quelle façon vos données collectées par Acceptables Avenirs sont-elles utilisées ?</h2>
    <p>Acceptables Avenirs utilise vos données pour :</p>
    <ul>
      <li>vous fournir les résultats synthétiques ou détaillés des tests ou des simulations que vous réalisez ;</li>
      <li>vous contacter pour résoudre des problèmes techniques ou d’usage de nos applications et logiciels accessibles
        via internet
      </li>
    </ul>
    <p>Avec votre accord exprès, Acceptables Avenirs pourra vous communiquer par courrier électronique des informations
      commerciales pour des services non analogues à ceux déjà fournis.</p>
    <p>Avec votre accord exprès, Acceptables Avenirs pourra communiquer vos coordonnées à ses prestataires pour les
      prestations que vous avez demandées ou autorisées.</p>
    <p>Ces données seront conservées pendant le temps nécessaire à la réalisation de ces finalités et pour une durée
      maximale de trois ans à compter de la première utilisation de nos applications et logiciels accessibles via
      internet concernant la prospection directe.</p>
    <p>Acceptables Avenirs pourra également utiliser vos données afin de respecter ses obligations légales et/ou pour
      répondre aux demandes des autorités publiques et gouvernementales</p>
    <h2>Comment pouvez-vous gérer la collecte et l’utilisation de vos données par Acceptables Avenirs ?</h2>
    <p>Vous pouvez à tout moment demander la rectification de vos données ou leur suppression de notre base de données
      en vous adressant au Directeur Général d’Acceptables Avenirs par courrier postal à l’adresse 20 rue Hermès, 31520
      Ramonville Saint Agne, ou par courrier électronique à <a
        href="mailto:info@acceptablesavenirs.eu">info@acceptablesavenirs.eu</a>.</p>
    <p>En cas de réclamation à laquelle Acceptables Avenirs ne vous aurait pas donné de réponse satisfaisante, vous
      pouvez vous adresser à la Commission Nationale de l’Informatique et des Libertés (CNIL) en charge du respect des
      obligations en matière de données personnelles.</p>
    <h2>Comment Acceptables Avenirs assure la sécurité de vos données ?</h2>
    <p>Acceptables Avenirs met en œuvre les actions nécessaires afin de protéger les données personnelles qu’elle
      traite. Vos données sont traitées de façon électronique et/ ou manuelle et en tout état de cause, de façon à ce
      que leur sécurité, protection et confidentialité soient assurées au regard de leur niveau de sensibilité, via des
      mesures administratives, techniques et physiques pour prévenir la perte, le vol, l’utilisation, la divulgation ou
      encore la modification non-autorisées.</p>
    <h2>Qui peut avoir accès à vos données personnelles et professionnelles ?</h2>
    <p>Vos données personnelles et professionnelles sont traitées par Acceptables Avenirs pour :</p>
    <ul>
      <li>les besoins de la bonne utilisation de ses applications et logiciels accessibles via internet ;</li>
      <li>l’exécution des prestations que vous avez demandées ou autorisées.</li>
    </ul>
    <p>
      Vos données peuvent également être transférées à nos prestataires avec votre accord exprès pour l’exécution des
      prestations que vous avez demandées ou autorisées

      Sur demande, vos données peuvent également être transférées aux personnes et autorités dont le droit d’accès aux
      données à caractère personnel est reconnu par la loi, les règlements ou les dispositions émanant d’autorités
      habilitées par la loi.

      Les sous-traitants et partenaires d’Acceptables Avenirs ne sont pas situés en dehors de l’Union Européenne.
    </p>
    <h2>Conditions d’évolution de notre politique de confidentialité.</h2>
    <p>Notre Politique de confidentialité pourrait évoluer en raison des évolutions législatives et réglementaires.</p>
  </Container>
);

export default Privacy;
