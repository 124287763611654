import './index.scss';
import React, {Component} from 'react';
import {apiCommunicationWithData, apiCommunication} from "../Utils/Api";
import {Card, Container, Checkbox, Icon} from "semantic-ui-react";
import { Link } from 'react-router-dom';

const QUERY_BUSINESSSEGMENTS = '/business_segments';
let inputRef = [];

class Validate extends Component {
  constructor(props) {
    
    super(props);

    this.state = {
      surveyID: props.location.state.surveyID,
      segment: props.location.state.segment,
      thankYou: false,
      accepted: false,
    };
    
    //Initialize or Re initiliaze in case of multiple survey
    inputRef = [];

    this.validateEmail = this.validateEmail.bind(this);
  }

  async componentDidMount() {
    const businessSegment = await apiCommunication('GET', `${QUERY_BUSINESSSEGMENTS}?code=${this.state.segment.toLowerCase()}`);
    this.setState({
      businessSegment: businessSegment['hydra:member'][0],
    });
  }

  toggleAccepted = (evt) => {
    this.setState({ accepted: !this.state.accepted })
  };

  setInputRef = (ref) => {
    inputRef.push(ref);
  };

  async validateEmail() {
    const email = inputRef[0].value;
    const code = inputRef[1].value;
    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    let err = false;
    let errMessage = '';
    if( email === '') {
      //Need to be skin when possible
      errMessage = 'Veuillez saisir un email';
      err = true;
    } else {
      if (!emailValid) {
        //Need to be skin when possible
        err = true;
        errMessage = 'Veuillez saisir une adresse email correcte';
      }
    }
    if(!err) {
      //Saving email in the API
      const data = {
        "businessSegment": this.props.segment,
        "email": email,
        "code": code
      };
      const response = await apiCommunicationWithData('POST', this.state.surveyID + '/validate', data);
      
      if (response.message && response.message === 'Invalid code') {
        inputRef[1].parentNode.classList.add('error');
        inputRef[1].parentNode.previousSibling.classList.add('error');
        inputRef[1].parentNode.childNodes[1].innerHTML = 'Code Invalide';
      }
      else {
        //Redirect to Thank You Page
        this.setState({ thankYou: true });
      }
    } else {
      inputRef[0].parentNode.classList.add('error');
      inputRef[0].parentNode.previousSibling.classList.add('error');
      inputRef[0].parentNode.childNodes[1].innerHTML = errMessage;
    }
  }

  render() {
    const {thankYou} = this.state;
    
    return (
      <Container className='custom-container form-content'>
        <h1 className='aligned center'>
          { this.state.businessSegment ? this.state.businessSegment.name : '' }</h1>
        {thankYou ?
          <div className='aligned center thankYou'>
            <div><Icon name='chevron circle down' /></div>
            <div className='description'>Merci ! Vous allez recevoir d’ici quelques minutes un mail avec les informations.</div>
            <div><Link to={'/'} className='ui button'>Accueil</Link></div>
          </div>
            :
          <div className='ui padded center aligned grid questions finalStep'>
            <div className='row active'>
              <Card>
                <Card.Content>
                  <Card.Header>Merci d’avoir pris le temps de remplir le questionnaire, vos réponses ont bien été prises en compte.</Card.Header>
                  <Card.Description>
                    <label htmlFor='emailConfirmation'>Saisissez votre adresse mail pour recevoir vos résultats :</label>
                    <div className='ui input'>
                      <input type='email' id='emailConfirmation' ref={this.setInputRef}/>
                      <span id='errorContent' className='errorContent'></span>
                    </div>
                    <p>
                      Validez directement ou saisir le code  fourni par votre partenaire
                    </p>
                    <div className='ui input'>
                      <input type='text' id='code' ref={this.setInputRef}/>
                      <span className='errorContent'></span>
                    </div>
                    <Checkbox
                      toggle
                      checked={this.state.accepted === true}
                      onChange={this.toggleAccepted}
                      label="J’accepte qu’Acceptables Avenirs me contacte pour me fournir les résultats synthétiques ou détaillés de mon test, ou pour résoudre des problèmes techniques ou d’usage de l’application." />
                      <p className="center">
                        <small>
                          Acceptables Avenirs vous recommande de consulter sa <Link to="/politique-vie-privee"
                                target="_blank">politique de vie privée</Link>.
                        </small>
                      </p>
                    <div className='aligned center'>
                      <button
                        disabled={!this.state.accepted}
                        className='large ui button'
                        onClick={() => {
                          this.validateEmail()
                        }}
                      >
                        Valider
                      </button>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
          </div>
        }
      </Container>
    )
  }
}

export default Validate;
