import React from 'react';

const Buttons = ({answers, value, onClick, questionId, categoryId}) => {
    return (
        [...answers].map((answer,index) =>
            <Button
                css={value === answer[1] ? 'ui button primary':'ui button'}
                onClick={onClick}
                value={answer[1]}
                questionId = {questionId}
                categoryId = {categoryId}
                data={answer[0]}
                key={index}
            >
                {answer[0]}
            </Button>
        )
    )
};

const Button = ({css, onClick, value, questionId, categoryId, data, children}) => {
    return (
        <button
            className={css}
            onClick={() => {onClick(data, value, questionId, categoryId)}}
        >
            {children}
        </button>
    )
};

const LinkBtn = ({css, onClick, children}) => {
    return (
        <button
            className={css}
            onClick={onClick}
        >
            {children}
        </button>
    )
};

export {
    Button,
    Buttons,
    LinkBtn
};
