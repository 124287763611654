import React from 'react';
import QuestionCard from './QuestionCard';
import Steps from './Steps';

const answersEnum = [
    ['Oui', true],
    ['Non', false],
    ['Je ne sais pas', null],
];

function generateClassName (indexCard, indexCategory, indexQuestion, activeQuestion, nbAnswersQuestions, isLast) {
    let className = 'row';

    if (indexCategory === 0 && indexQuestion === 0) className += ' first';
    if (indexCard === activeQuestion) {
        className += ' active';
    }
    if (indexCard < activeQuestion) className = className + ' end';
    if (indexCard === activeQuestion && indexCard < nbAnswersQuestions) className = className + ' previous';
    if (indexCard < nbAnswersQuestions) className = className + ' answered';
    if (isLast) className = className + ' active last';

    return className;
}

const QuestionList = ({activeQuestion, nbAnswersQuestions, categories, questions, survey, segment, answers, nextStep, prevStep}) => {
    if (categories.length === 0) {
        // State : Loading the questions from the API
        return (
            <div className='aligned center loading-container'>
                <div className="ui active mini inline loader"/>
                &nbsp;&nbsp;&nbsp;&nbsp;Chargement des questions
            </div>
        );
    }
    // Everything is loaded. Show the questions
    let i = 0;
    let activeCat = 0;
    const html = [...categories].map((category, indexCategory) => {
        return [...category['questions']].map((question, indexQuestion) => {
            const isLast = i === questions.length-1 && activeQuestion >= questions.length-1;

            let answer = '';
            if (answers.length > i) {
                answer = answers[i];
            }


            if (i === activeQuestion) {
                activeCat = category['id'];
            }

            const className = generateClassName(i, indexCategory, indexQuestion, activeQuestion, nbAnswersQuestions, isLast);

            i++;

            return (
                <div className={className} key={question.id}>
                    <QuestionCard
                        isLast={isLast}
                        answer={answer}
                        answers={answersEnum}
                        category={category['id']}
                        question={question}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        survey={survey}
                        segment={segment}/>
                </div>
            );
        });
    });

    return (
        <div>
            <Steps
                categoriesTitle={categories}
                activeCategoryId={activeCat}
            />
            <div className='ui grid padded center aligned questions'>
                {
                    html
                }
            </div>
        </div>
    );
};

export default QuestionList;
