import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './index.scss';

function Layout(components) {
  const {Content} = components;

  class Component extends React.Component{
    render() {
      return (
        <div>
          <div className='content-top'>
            <Header/>
            <div className="Content">
              <Content {...this.props}/>
            </div>
          </div>
          <Footer/>
        </div>
      );
    }
  }

  return Component;
}

export default Layout;
