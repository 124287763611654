import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {apiCommunication} from '../Utils/Api';
import { Card, Grid, Image } from 'semantic-ui-react';

const QUERY_BUSINESSSEGMENTS = '/business_segments';

class BusinessSegmentCards extends Component {
  constructor(props) {

    super(props);

    this.state = {
      businessSegments: null
    };
  }

  async componentDidMount() {
    const businessSegments = await apiCommunication('GET', `${QUERY_BUSINESSSEGMENTS}`);
    this.setState({
      businessSegments: businessSegments['hydra:member'],
    });
  }

  render () {
    const BusinessSegmentCard = ({name, type, description, picto}) => {
      return <Card>
        <Card.Content textAlign="center">
          <div><Image src={picto} /></div>
          <Card.Header><h2>{name}</h2></Card.Header>
          <Card.Description>{description}</Card.Description>
          <Link 
            className="ui button large" 
            to={{
              pathname: `/s/${type}`,
              state: {segment: type}
            }}
            title="Carrière">Commencez maintenant !</Link>
        </Card.Content>
      </Card>;
    };

    const { businessSegments } = this.state;
    
    return (
      <Grid>
        <Grid.Row>
          {businessSegments ? (businessSegments.map(segment => (
            <Grid.Column key={segment.id}>
              <BusinessSegmentCard
                name={segment.name}
                type={segment.code}
                picto={segment.picto}
                description={segment.description}/>
            </Grid.Column>
          ))) : ''}
        </Grid.Row>
      </Grid>
    );
  }
}



export default BusinessSegmentCards;