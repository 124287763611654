import {fetch} from '../Utils/dataAccess';

export function apiCommunicationWithData(method, destination, data = '') {
  return fetch(destination, {
    method: method,
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data)
  }).then(
    res => res.json()
  ).then(
    (data) =>  { return data; }
  ).catch(err => err);
}

export function apiCommunication(method, destination) {
  return fetch(destination, {
    method: method,
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }).then(
    res => res.json()
  ).then(
    (data) =>  {return data;}
  ).catch(err => err);
}
