import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Grid } from 'semantic-ui-react';
import { BpiImage, IntroductionImage, OccitanieImage } from '../../config/Images';
import BusinessSegmentCards from './BusinessSegmentCards';
import './index.scss';

const Homepage = () => (
  <div className='homepage'>
    <Container className='custom-container'>
      <Grid className='introduction'>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16} tablet={6} className='center aligned'>
            <IntroductionImage/>
          </Grid.Column>
          <Grid.Column computer={10} mobile={16} tablet={10}  className='middle aligned'>
            <Container textAlign='center'>
              <h1>Démarrez le prédiagnostic stAArt en prenant 2 minutes pour compléter notre questionnaire</h1>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className='howto'>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Container>
              <div className='title center aligned'>Déroulement</div>
              <ol>
                <li className="list-item">
                  Vous cliquez sur le secteur de votre choix pour commencer l’évaluation
                </li>
                <li>
                  Vous répondez aux questions. Il vous faut environ 2 minutes
                </li>
                <li>
                  Vous recevez un mail vous confirmant que nous avons bien pris en compte vos réponses. Vous obtenez une première estimation du niveau global d’acceptabilité de votre projet.
                </li>
                <li>
                  Nous entrons en contact pour approfondir vos résultats selon la formule souhaitée.
                </li>
              </ol>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <div className='forms-content'>
      <div className='forms'>
        <Container className='custom-container'>
          <div className='title'>
            Répondez au questionnaire afin de débuter l’évaluation de votre projet
          </div>
          <BusinessSegmentCards />
        </Container>
      </div>
    </div>
    <Container className="custom-container customer--quote">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <blockquote>
              « J'avoue qu'en remplissant le questionnaire j'ai été surpris du peu de questions et interrogatif sur leur
              pertinence.
              Votre rendu tire des conclusions tout à fait intéressantes, notamment sur la crédibilité du
              projet/respect des engagements et sur les liens à maintenir avec les acteurs locaux. Le rendu est
              percutant ! »
            </blockquote>
            <p>
              <small>Témoignage d’un porteur de projet de biogaz</small>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    <Container className='custom-container'>
      <div className="partnership aligned center"><span>Ils nous soutiennent</span> <OccitanieImage/> <BpiImage/></div>
    </Container>
  </div>
);

const BusinessSegmentCard = ({name, type, description, Icon}) => {
  return <Card>
    <Card.Content textAlign='center'>
      <div><Icon/></div>
      <Card.Header><h2>{name}</h2></Card.Header>
      <Card.Description>{description}</Card.Description>
      <Link className='ui button large' to={`/${type}`} title='Carrière'>Commencez maintenant !</Link>
    </Card.Content>
  </Card>;
};
BusinessSegmentCard.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.any,
};

export default Homepage;
