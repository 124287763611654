import React from 'react';
import {Link} from "react-router-dom";

const Header = () => (
  <header className='ui container custom-container'>
    <div className='ui padded center aligned grid'>
      <div className='row'>
        <Link to="/" className='logo'>
          <strong>stAArt</strong><br/>par Acceptables Avenirs
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
