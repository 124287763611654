import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {apiCommunication, apiCommunicationWithData} from '../Utils/Api';
import QuestionList from './QuestionList';

const QUERY_SURVEY = '/surveys';
const QUERY_QUESTIONS = '/categories.jsonld';
const QUERY_ANSWERS = '/answers';

class FormFields extends Component {
  constructor(props) {

    super(props);

    this.state = {
      categories: [],
      questions: [],
      answers: [],
      activeQuestion: 0,
      nbAnswersQuestions: 0,
      survey: null  //Will store the survey id
    };

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
  }

  componentDidMount() {
    const surveyJSON = {
      businessSegment: `/business_segments/${this.props.segment.toLowerCase()}`
    };
    const res = apiCommunicationWithData('POST', QUERY_SURVEY, surveyJSON);
    const questions = apiCommunication('GET', `${QUERY_QUESTIONS}?businessSegment=${this.props.segment.toLowerCase()}`);
    Promise.all([res, questions]).then((values) => {
      this.setState({
        survey: values[0]['@id'],
        categories: values[1]['hydra:member'],
        questions: values[1]['hydra:member'].reduce((reducer, category) => {
          reducer.push(...category.questions);
          return reducer;
        }, []),
      });
    })
  }

  prevStep() {
    this.setState({
      activeQuestion: this.state.activeQuestion - 1
    });
  }

  /**
   * This is use when clicked on a response or Next Button (no data)
   */
  nextStep(data = null, value = '', questionId = null, categoryId = null) {
    //Answer is clicked ?
    if (value !== '') {
      //Need to send data to the API
      const datas = {
        "survey": this.state.survey,
        "question": questionId,
        "value": value
      };
      apiCommunicationWithData('POST', QUERY_ANSWERS, datas);
    }

    let nbAnswersQuestions = this.state.nbAnswersQuestions;
    const activeQuestion = this.state.activeQuestion;

    if(this.state.activeQuestion === this.state.nbAnswersQuestions) {
      nbAnswersQuestions++;
    }
    if((this.state.answers.length >= activeQuestion) && (value !== '')) {
      //Update State
      this.setState({
        answers: update(this.state.answers, {[activeQuestion]:  {$set: value}}),
        activeQuestion: activeQuestion < this.state.questions.length-1  ? activeQuestion+1 : activeQuestion,
        nbAnswersQuestions: nbAnswersQuestions
      });
    } else {
      //Insert State
      this.setState({
        answers: [...this.state.answers, value],
        activeQuestion: activeQuestion+1,
        nbAnswersQuestions: nbAnswersQuestions
      });
    }
  }

  render () {
    const { categories, survey, answers, activeQuestion, nbAnswersQuestions } = this.state;
    return (
      <div>
        <p className='introduction'>Notre questionnaire concerne 4 rubriques :<br/>
          le territoire, votre entreprise, votre projet et votre démarche.</p>
        <p className='introduction'><strong>Pour chaque question, vous pouvez répondre par oui/non/je ne sais pas.</strong></p>
        <QuestionList
          activeQuestion={activeQuestion}
          nbAnswersQuestions={nbAnswersQuestions}
          categories={categories}
          questions={this.state.questions}
          survey={survey}
          segment={this.props.segment}
          answers={answers}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
        />
      </div>
    );
  }
}
FormFields.propTypes = {
  segment: PropTypes.string,
};



export default FormFields;
